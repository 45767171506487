<template>
  <v-container>
    <v-row>
      <v-col :md="5" :cols="12">
        <v-card class="pa-5 ma-5" elevation="24">
          <h1 class="pb-5">Login</h1>
          <v-text-field
            outlined
            color="grey"
            v-model="email"
            ref="email"
            label="Email"
          ></v-text-field>
          <v-text-field
            outlined
            color="grey"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            v-model="password"
            class="input-group--focused"
            @click:append="showPassword = !showPassword"
            v-on:keyup.enter="login()"
          ></v-text-field>
          <v-btn large block color="" :loading="loading" :disabled="disabled" @click="login()">Login</v-btn>
          <p class="pt-5 pl-5 red--text" v-if="msg">{{ msg }}</p>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-5 ma-5" elevation="10" max-width="400">
          <h1>Not a member yet?</h1>
          <v-btn class="mt-5" @click="signup()">Sign up now!</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserService from "../services/UserService";
import { store } from "../store/store";

export default {
  name: "Login",
  components: {},
  data: () => ({
    store,
    password: "",
    email: "",
    showPassword: false,
    msg: "",
    loading: false
  }),
  created() {
    if (this.store && this.store.user.email) {
      this.email = this.store.user.email;
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.$refs.email.focus()
    // })
  },
  computed: {
    disabled() {
      return (this.password.length < 1 || this.email.length < 1)
    }
  },
  methods: {
    async login() {
      this.loading = true
      await UserService.login(this.email, this.password)
        .then((response) => {
          this.$cookies.set("token", response.token);
          this.store.isAuthenticated = true;
          this.loading = false
          this.$router.push("/").catch(() => {});
        })
        .catch((err) => {
          console.log(err);
          this.msg = "Username or password is incorrect";
          this.loading = false
        });
    },
    signup() {
      this.$router.push("/signup");
    },
  },
};
</script>